import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { 
    Container,
    Box,
    Flex,
    Space,
    Text,
    Heading,
    List,
} from "../components/ui"

const Project = ({ data }) => {
    return (
        <Layout>
            <Container>
                <Box paddingY={5}>
                    <Heading>
                    {data.project.projectInfo.name}
                    </Heading>
                    <Space size={6} />

                    <Flex>
                        <Text variant="bold">{data.project.projectInfo.type}</Text>
                        <Text variant="bold">{data.project.projectInfo.industry}</Text>
                    </Flex>
                </Box>
                <Space size={4} />
                <List>
                {data.project.type}

                </List>
                <Text center>Project date: {data.project.date}</Text>
            </Container>

        </Layout>
    )
}

export default Project

export const pageQuery = graphql`
    query($slug: String!) {
        project: contentfulProject(slug: { eq: $slug }) {
            slug
            active
            date
            type
            projectInfo {
                name
                type
                industry
            }
        }
    }
`
